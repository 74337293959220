import axios from "axios";
import Cookies from "js-cookie";

import { getLoginHref } from "@/lib/helpers";

const client = axios.create();
client.defaults.baseURL = "/api";
client.defaults.timeout = 10000;
client.defaults.xsrfCookieName = "gobo_csrftoken";
client.defaults.xsrfHeaderName = "X-CSRFToken";
client.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error?.response?.status || null;

    // get a new session if the current one has already expired
    if ((status === 401 || status === 403) && !Cookies.get("gobo_session")) {
      window.location.assign(getLoginHref());
    }

    let goboError = {
      status: status,
      statusText: "",
      message: null,
      messages: null,
      error: error,
    };

    if (error.response) {
      goboError.statusText = error.response.statusText;
      if (status === 400) {
        goboError.messages = error.response.data;
        if (error.response.data.non_field_errors) {
          goboError.message = error.response.data.non_field_errors;
          delete goboError.messages.non_field_errors;
        }
      } else if (status < 500) {
        if (error.response.data.detail) {
          goboError.message = error.response.data.detail;
        } else {
          goboError.message = error.toJSON().message;
        }
      } else {
        goboError.message = "Server error.";
      }
    } else if (error.request) {
      goboError.message = "Error connecting to sever.";
    } else {
      goboError.message = "Unknown error.";
    }

    throw goboError;
  },
);

export default client;
